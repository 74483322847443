import React from "react"
import Layout from "../../components/Layout/Layout"
import InputPatientDetails from "../../components/Epharmacy/SpecialOrder/InputPatientDetails"

export default ({ location }) => (
  <Layout
    title="Input Patient Details"
    subtitle={
      <span>
        Upload your patient's prescription and search for their medicines.
      </span>
    }
    process="epharmacy"
    seoTitle="Input Patient Details"
    display={{ footer: false, helpCenterBanner: false }}
    path={location?.pathname}
    underMaintenance={
      process.env.GATSBY_HAS_SPECIAL_ORDER_ENABLED === "disabled"
    }
  >
    <InputPatientDetails locationState={location?.state || {}} />
  </Layout>
)
